@font-face{
    font-family:Salita;
    src: url(../Font/salita/Salita.ttf);
}
.storydetilsdivmain{
    width: 100%;
    height: auto;
    padding: 80px 0px 0px 0px;
}
.srtyydetailstxtp{
    font-size: 61px;
    font-family: AbolitionTestRegular;
    font-weight: 700;
    line-height: 55px;
    margin-bottom: 30px;
    letter-spacing: 3px;
}

.storydetlas_txtp{
    color: #0171b1;
    font-size: 37px;
    width: 100%;
    font-weight: 500;
    font-family: "Lato", sans-serif;
    margin-bottom: 50px;
}
.storydetailsimg{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 470px;
}
.storydetailsimg img{
    width: 100%;
    height: 100%;
}
.stotyimgtextdivflx{
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
}
.cygnusprofetxtopp{
    font-family: "Lato", sans-serif;
    font-size: 25px;
    color: #000;
    line-height: 44px;
    font-weight: 400;
    margin-top: 10px;
}
.storydetailsdiv{
    width: 100%;
    max-width: 51%;
    min-width: 51%;
    margin-top: 84px;
}
.storydetailsimgdiv{
    width: 100%;
    max-width: 44%;
    min-width: 44%;
}
.missionvissionmain{
    width: 100%;
    height: auto;
    padding: 80px 0px 0px 0px;
}
.missionvissontxtpp{
    font-family: AbolitionTestRegular;
    font-size: 50px;
    line-height: 65px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
}
.miisoonvissiondiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}
.miisondiv{
    width: 100%;
    max-width: 48%;
    min-width: 48%;
}
.visiondiv{
    width: 100%;
    max-width: 48%;
    min-width: 48%;   
}
.miisondivbrdr{
    width: 100%;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 20px 45px;
}
.visondivbrdr{
    width: 100%;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 20px 45px;
}
.imhmisionvison{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 490px;
}
.imhmisionvison img{
  width: 100%;
  height: 100%;
  border-radius: 40px;
}
.misisontxtpp{
    font-family: AbolitionTestRegular;
    font-size: 38px;
    color: #000;
    line-height: 31px;
    font-weight: 500;
    text-align: center;
    margin-top: 40px;
}
.misisontxtppmaino {
    font-family: "Lato", sans-serif;
    font-size: 22px;
    color: #000;
    line-height: 31px;
    font-weight: 400;
    margin-top: 39px;
}
.misisontxtppmainodiv{
  height: 372px;
}
.imhagmiidllestpittstmaion{
    width: 100%;
    max-width: 800px;
    min-width: 800px;
    height: 400px;
    margin: auto;
    padding-top: 88px;
}
.imhagmiidllestpittstmaion img{
    width: 100%;
    height: 100%;
}

.stotyimgtextdivwrapper{
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
}
.bannerimagdspdoibbfkl{
    width: 100%;
    max-width: 600px;
    height: 616px;
    min-width: 617px;
    max-width: 617px;
    float: right;
    margin-left: 20px;
}

@media(max-width:1399px){
    .misisontxtppmaino {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
    }
}
@media(max-width:1199px){
    .cygnusprofetxtopp {
        font-size: 23px;
        line-height: 41px;
        margin-top: 10px;
    }
    .misisontxtppmaino {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        line-height: 29px;
    }
    .storydetlas_txtp {
        color: #0171b1;
        font-size: 30px;
        margin-bottom: 41px;
    }
}
@media(max-width:1024px){
    .cygnusprofetxtopp {
        font-size: 20px;
        line-height: 36px;
        margin-top: 10px;
    }
}

@media(max-width:991px){
    .storydetilsdivmain {
        padding: 40px 0px 0px 0px;
    }
    .stotyimgtextdivflx{
        flex-wrap: wrap;
    }
    .storydetailsdiv{
        width: 100%;
        max-width: 100%;
    }
    .storydetailsimgdiv{
        width: 100%;
        max-width: 100%;
    }
    .misisontxtppmainodiv{
        height: auto;
    }
    .storydetailsimg {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
    }
    .storydetailsdiv{
        margin-top: 0px;
    }
    .cygnusprofetxtopp {
        font-size: 18px;
        line-height: 32px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .storydetlas_txtp {
        color: #0171b1;
        font-size: 30px;
        margin-bottom: 20px;
    }
    .miisoonvissiondiv{
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .miisondiv{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .visiondiv{
        width: 100%;
        max-width: 100%;
        min-width: 100%;   
    }
    .misisontxtppmaino{
        height: auto;
    }
    .missionvissionmain{
      padding: 40px 0px 0px 0px;

    }

    .imhagmiidllestpittstmaion {
        width: 100%;
        max-width: 736px;
        min-width: 0px;
        height: 336px;
        margin: auto;
        padding-top: 69px;
    }    
    
}
@media(max-width:767px){
    
    .srtyydetailstxtp {
        font-size: 45px;
        line-height: 52px;
        margin-bottom: 20px;
    }
    .missionvissontxtpp {
        font-size: 45px;
    }
    .miisoonvissiondiv {
        margin-top: 35px;
    }
}
@media (max-width: 576px) {
    .srtyydetailstxtp {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 14px;
    }
    .misisontxtppmaino {
        margin-top: 25px;
    }
    .storydetlas_txtp {
        color: #0171b1;
        font-size: 26px;
        margin-bottom: 10px;
    }
    .cygnusprofetxtopp {
        font-size: 17px;
        line-height: 30px;
        margin-top: 13px;
        margin-bottom: 10px;
    }
    .storydetilsdivmain {
        padding: 20px 0px;
    }
    .missionvissionmain{
        padding: 20px 0px;
    }
    .missionvissontxtpp {
        font-size: 40px;
    }
    .miisoonvissiondiv {
        margin-top: 20px;
    }
    .misisontxtppmaino {
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        line-height: 27px;
    }
    .imhmisionvison{
        height: auto;
    }
    .misisontxtpp {
        font-family: "Montserrat", sans-serif;
        font-size: 25px;
        line-height: 31px;
    }
    .visondivbrdr {
        padding: 20px 25px;
    }
    .miisondivbrdr {
        padding: 20px 25px;
    }
}
@media(max-width:480px){
    .storydetlas_txtp {
        color: #0171b1;
        font-size: 23px;
        margin-bottom: 10px;
    }
    .misisontxtppmaino {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        line-height: 22px;
    }
    .srtyydetailstxtp {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .cygnusprofetxtopp {
        font-size: 15px;
        line-height: 23px;
        margin-top: 13px;
        margin-bottom: 10px;
    }
    .misisontxtppmaino {
        margin-top: 17px;
    }
    .misisontxtpp {
        margin-top: 30px;
    }
}

@media (max-width: 420px) {
    .storydetlas_txtp {
        color: #0171b1;
        font-size: 19px;
        margin-bottom: 9px;
    }
    .missionvissontxtpp {
        font-size: 30px;
        margin-bottom: 15px;
    }
}