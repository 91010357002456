@font-face {
    font-family: Salita;
    src: url(../Font/salita/Salita.ttf);
}

.ourfounder {
    width: 100%;
    height: auto;
    padding: 80px 0 0px 0px;
}

.ourfounderdivflx {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.founderimgdivmain {
    width: 100%;
    max-width: 35%;
    min-width: 35%;
}

.foundertext {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
}

.founderimgdiv {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 310px;
}

.founderimgdiv img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.foundertextpp {
    font-family: "Lato", sans-serif;
    font-size: 22px;
    color: #000;
    line-height: 31px;
    font-weight: 400;
}

.foundertxttpp {
    font-family:AbolitionTestRegular;
    font-size: 50px;
    color: #000;
    text-align: center;
    line-height: 35px;
    font-weight: 400;
    margin-bottom: 20px;
}

.Vemtetxtppp {
    font-family: "Lato", sans-serif;
    font-size: 26px;
    color: #000;
    line-height: 31px;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
}

.founndtethhtstp {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    color: #000;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
}

.expertteammainsection {
    width: 100%;
    height: auto;
    padding: 80px 0px 0px 0px;
}

.experteamhhh {
    font-family: AbolitionTestRegular;
    font-size: 60px;
    line-height: 65px;
    font-weight: 800;
    text-align: left;
    margin-bottom: 30px;
    width: 100%;
    max-width: 40%;
    min-width: 40%;
    letter-spacing: 1px;
}
.descriptiondivmainbox{
    position: absolute;
    top: 2px;
    /* right: 50%; */
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    width: 100%;
    /* max-width: 334px; */
    /* min-width: 334px; */
    padding: 20px 25px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    opacity: 0;
    transition: .5s all ease-in-out;
}
.desigbtetxttppyyuuyp{
    font: 400 11px / 15px "Lato", sans-serif;
    color: #000;
}
.experteamdivbg {
    width: 100%;
    max-width: 24%;
    min-width: 24%;
    position: relative;
    cursor: pointer;
}
.experteamdivbg img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.experteamdivbg:hover .descriptiondivmainbox{
    opacity:1 ;
    transition: .5s all ease-in-out;
}

.experteamdivbgdiv {
    background-color: #f3b2c6;
    width: 100%;
    border-top-left-radius: 135px;
    border-top-right-radius: 135px;
    border-bottom-left-radius: 135px;
    border-bottom-right-radius: 135px;
    overflow: hidden;
}

.experteamdivbgdivtop {
    padding-top: 50px;
    padding-bottom: 50px;
}

.experttemname {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: #000000;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 6px;
}

.experttemnamedesg {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #0000007c;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
}

.experteamdivbgdivbottom {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 427px;
}

.experteamdivbgdivbottom img {
    width: 100%;
    height: 100%;
}

.experteamdivbg_main {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 40px;
}

.experteamdivbg:nth-child(2) .experteamdivbgdiv {
    background-Color: #dbd2cb;
}

.experteamdivbg:nth-child(3) .experteamdivbgdiv {
    background-Color: #c3cdcc;
}

.experteamdivbg:nth-child(4) .experteamdivbgdiv {
    background-Color: #f5c12f;
}

.experteamdivbg:nth-child(5) .experteamdivbgdiv {
    background-Color: #dbd2cb;
}

.experteamdivbg:nth-child(6) .experteamdivbgdiv {
    background-color: #f3b2c6;
}


.experteamdivbg:nth-child(7) .experteamdivbgdiv {
    background-Color: #f5c12f;
}

.experteamdivbg:nth-child(8) .experteamdivbgdiv {
    background-Color: #c3cdcc;
}
.experteamdivbg:nth-child(9) .experteamdivbgdiv {
    background-Color: #f5c12f;
}
.experteamdivbg:nth-child(10) .experteamdivbgdiv {
    background-Color: #c3cdcc;
}
.experteamdivbg:nth-child(11) .experteamdivbgdiv {
    background-Color: #dbd2cb;
}
.experteamdivbg:nth-child(12) .experteamdivbgdiv {
    background-color: #f3b2c6;
}

.experteamimimgadvisorimg{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 390px;
}
.experteamimimgadvisorimg img{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;  
} 
.experteamimimgadvisorimgdiv{
    background-color: #0e0e0e;
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
}
.ServicesHome_sec .swiper-slide{
    margin-right: 19px !important;
    margin-left: 7px !important;
}
.ctgsttetxtmnertxtpp{
    font-family: "Lato", sans-serif;
    font-size: 23px;
    color: #000;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 6px;  
    padding-top: 15px;
}
.dattatstxtpp{
    font-family: "Montserrat", sans-serif;
    font-size: 23px;
    color: #000;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
    padding-bottom: 4px;
    padding-top: 2px;
}
/* .swiper-slide:last-child{  
    margin-right: 0px !important;
} */
@media(max-width:1399px) {
    .foundertextpp {
        font-size: 20px;
    }
    .experteamdivbg_main {
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 40px;
    }
    .experteamdivbg {
        width: 100%;
        max-width: 18%;
        min-width: 18%;
        position: relative;
        cursor: pointer;
    }
}
@media(max-width:1264px){
    /* .experteamdivbg_main {
        display: flex;
        justify-content: flex-start;
       
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 40px;
    }
    .experteamdivbg {
        width: 100%;
        max-width: 22%;
        min-width: 22%;
    } */
    .experteamdivbg_main {
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        flex-wrap: wrap;
        column-gap: 39px;
        row-gap: 40px;
    }
    .experteamdivbg {
        width: 100%;
        max-width: 24%;
        min-width: 24%;
        position: relative;
        cursor: pointer;
    }
}

@media (max-width: 1199px) {
    .foundertextpp {
        font-size: 17px;
        line-height: 25px;
    }
    .experteamdivbgdivbottom {
        height: 330px;
    }
    .experteamdivbg {
        width: 100%;
        max-width: 17%;
        min-width: 17%;
    }
    .experteamdivbg_main {
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        flex-wrap: wrap;
        column-gap: 35px;
        row-gap: 40px;
    }
    .founderimgdiv {
        height: 287px;
    }
    .founderimgdivmain {
        width: 100%;
        max-width: 40%;
        min-width: 40%;
    }

    .Vemtetxtppp {
        font-size: 25px;
    }

    .founndtethhtstp {
        font-size: 19px;
    }
    .experteamhhh {
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }
}

@media(max-width:991px) {
    .ourfounderdivflx {
        flex-wrap: wrap;
    }
    .experteamhhh {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .founderimgdivmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .experteamdivbg {
        width: 100%;
        max-width: 29%;
        min-width: 29%;
    }
    .experteamdivbg_main {
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        flex-wrap: wrap;
        column-gap: 46px;
        row-gap: 40px;
    }
    .foundertext {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .founderimgdiv {
        height: auto;
    }

  

    .foundertextpp {
        font-size: 18px;
        line-height: 25px;
        margin-top: 10px;
    }
    .experteamdivbg:nth-child(even) {
        padding-top: 0px;
    }
    .experteamdivbg{
        padding-bottom: 30px;
    }
    .expertteammainsection {
        padding: 40px 0px;
    }
    .ourfounder {
        padding: 40px 0 40px 0px;
    }
}
@media(max-width:767px){
    .experteamdivbg {
        width: 100%;
        max-width: 44%;
        min-width: 44%;
    }
    .foundertxttpp {
        font-family: Salita;
        font-size: 45px;
        margin-bottom: 20px;
    }
    .experteamdivbgdivbottom {
        height: 420px;
    }
    .experteamhhh {
       font-size: 45px;
    }
    .experteamimimgadvisorimg {
        height: 300px;
    }
    .ServicesHome_sec .swiper-slide{
        margin-right: 17px !important;
    }
}

@media(max-width:576px){
    .experteamhhh {
        font-size: 40px;
        line-height: 48px;
     }
     .expertteammainsection {
        padding: 20px 0px;
    }
    .foundertxttpp {
        font-family: Salita;
        font-size: 40px;
        margin-bottom: 20px;
    }
    .ourfounder {
        padding: 20px 0 20px 0px;
    }
    .ServicesHome_sec .swiper-slide {
        margin-right: 8px !important;
    }
  
}

@media(max-width:480px) {
    .foundertxttpp {
        font-family: Salita;
        font-size: 30px;
    }

    .foundertextpp {
        font-size: 15px;
        line-height: 23px;
        margin-top: 10px;
    }
    .experteamhhh {
        font-size: 30px;
        line-height: 38px;
     }
     .experteamdivbgdivbottom {
        height: 350px;
    }
    .experteamdivbg_main {
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 40px;
    }
}

@media(max-width:420px){
    .experteamdivbgdivbottom {
        height: 320px;
    }
    .experttemname {
        font-family: "Montserrat", sans-serif;
        font-size: 21px;
    }
    .experttemnamedesg {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
    }
    .experteamimimgadvisorimg {
        height: 355px;
    }
    .experteamdivbg_main {
        display: flex;
        justify-content: flex-start;
        /* align-items: center; */
        flex-wrap: wrap;
        column-gap: 38px;
        row-gap: 3px;
    }
    .experteamdivbg {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}