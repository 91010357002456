.contactus_sec {
    width: 100%;
    height: auto;
    padding: 80px 0;
    position: relative;
}

.contactus_sec .bgcolor {
    width: 100%;
    max-width: 15%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-color: #0171b1;
    top: 0;
    right: 0;
}

.contactus_sec .left_content {
    width: 100%;
    max-width: 45%;
    height: auto;
}

.contactus_sec form .form-group {
    margin-bottom: 30px;
}

.contactus_sec form .form-control {
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-bottom: 2px solid #999898;
    font-weight: 400;
    outline: 0;
    color: #fff;
}

.contactus_sec form .form-control::placeholder {
    font-weight: 600 !important;
    color: #999898;
}

.Submit_btn {
    width: 100%;
    max-width: 200px;
    height: 45px;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    color: #fff;
    background-color: #0171b1;
    border-radius: 10px;
    font-size: 18px;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactus_sec .right_content {
    width: 100%;
    max-width: 500px;
    height: auto;
    padding: 40px;
    border-radius: 18px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.contactus_sec .head {
    font-size: 40px;
    text-align: left;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin-bottom: 20px;
    
    font-family: AbolitionTestRegular;
}

.info_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.info_box i {
    color: #000;
    margin-right: 15px;
    font-size: 20px;
    margin-top: 5px;
}

.info_box span {
    font-size: 20px;
    text-align: center;
    color: #000;
    font-weight: 400;
    font-family: "Lato", sans-serif;
}

.info_box p {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #646363;
    margin-bottom: 20px;
    font-family: "Lato", sans-serif;
}

.info_box .text1 {
    margin-right: 15px;
    white-space: nowrap;
    color: #000;
    width: 100%;
    max-width: 100px;
}




@media (max-width: 1024px) {
    .contactus_sec .bgcolor {
       display: none;
    }
    .contactus_sec .left_content {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-bottom: 30px;
        position: relative;
    }
    .contactus_sec .right_content {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

@media(max-width:991px){
    .contactus_sec {
        padding: 40px 0;
    }
}

@media(max-width:576px){
    .contactus_sec {
        padding: 20px 0;
    }
}