@font-face {
    font-family: Salita;
    src: url(../Font/salita/Salita.ttf);
}

.workmainsection {
    width: 100%;
    height: auto;
    padding: 80px 0px;
    position: relative;
}

.worlttstxthhh {
    font-size: 50px;
    text-align: center;
    color: #000;
    line-height: 60px;
    /* font-family: Salita; */
    margin-bottom: 10px;
    /* font-family: DiastemaBoldItalic; */
    font-family: AbolitionTestRegular;
}

.jiointxtdtnamirxrp {
    font-family: "Lato", sans-serif;
    font-size: 25px;
    color: #000;
    line-height: 44px;
    font-weight: 400;
    margin-top: 10px;
    width: 100%;
    max-width: 45%;
    min-width: 45%;
    margin: auto;
    text-align: center;
}

.workimgfrstr {
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    height: 300px;
    position: relative;
    padding-top: 52px;
}

.workimgfrstr::after {
    position: absolute;
    content: "";
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 66px;
    background-color: #0171b1;
    top: 243px;
    right: -38px;
    /* left: -11%; */
    border-top-left-radius: 30px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 30px;
}

.workimgfrstr::before {
    position: absolute;
    content: "";
    width: 100%;
    max-width: 103px;
    min-width: 103px;
    height: 52px;
    background-color: #03A9F4;
    bottom: -62px;
    right: 51px;
    border-top-left-radius: 33px;
    border-top-right-radius: 31px;
    border-bottom-left-radius: 38px;
}

.workimgfrstr img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 116px;
    border-bottom-left-radius: 108px;
    border-bottom-right-radius: 108px;
}

.whyworkdivmain {
    width: 100%;
    max-width: 31%;
    min-width: 31%;
}

.whyworkdivmainimg {
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    height: 60px;
    margin: auto;
}

.whyworkdivmainimg img {
    width: 100%;
    height: 100%;

}

.whyworkdivmaintxtpp {
font-family: "Lato", sans-serif;
    font-size: 18px;
    color: #000;
    line-height: 28px;
    font-weight: 400;
    margin-top: 7px;
}

.whyworkdivmaindivflx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 40px;
    flex-wrap: wrap;
    row-gap: 40px;
    padding-top: 20px;
}

.carrertxtteamhhh {
    font-size: 70px;
    line-height: 65px;
    font-weight: 800;
    text-align: left;
    margin-bottom: 30px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    font-family: AbolitionTestRegular;
    letter-spacing: 3.4px;
    color: #fff;
}

.carrerfrmdivmaib {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.carrerimgdivmain {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.carrerimgdivmainimg {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 720px;
    margin-left: 114px;
    position: relative;
    z-index: 2;
}

.carrerimgdivmainimg img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 140px;

}

.carrerfrmdivmaibdivflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.froimyttstxtarrowdivttx {
    font-size: 30px;
    font-weight: 500;
    color: #0171b1;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.froimyttstxtarrowdivttx span {
    font-weight: 900;
    margin-left: 148px;
    color: #fff;
}

.drpediowocliclkul {
    list-style: none;
}

.drpediowocliclkul {
    font-size: 20px;
    line-height: 28px;
    color: #000;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
}

.frtrwdivnainkkk {
    margin-top: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #000;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-bottom: 80px;
}

.frtrwdivnainkkk.chng {
    padding-bottom: 0px;
}

.seemoremnbbtgbtn {
    color: #fff;
    background-color: #0171b1;
    padding: 10px 15px;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    border: none;
    outline: 0;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.workimgfrstrimgright {
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    height: 322px;
    position: relative;
}

.workimgfrstrimgright::before {
    position: absolute;
    content: "";
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 52px;
    background-color: #0171b1;
    top: 0;
    left: -15%;
    border-top-left-radius: 20px;
    border-top-right-radius: 27px;
    border-bottom-right-radius: 26px;
}

.workimgfrstrimgright img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 105px;
    border-bottom-left-radius: 108px;
    border-bottom-right-radius: 108px;
}

.workimgfrstrrimgrightdivflx {
    display: flex;
    column-gap: 50px;
    justify-content: center;
    padding-top: 35px;
}

.clientsayasmain {
    /* background-color: #0171b1; */
    padding: 50px 50px;
    margin: 50px 0px;
}
.clientsayasmaincontentdiv{
    background-color: #0171b1;
    width: 100%;
    max-width: 100%;
    padding: 50px;
}

.employeuimgtextdivflxsld {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 61px;
    padding-top: 60px;
}

.climetsaysyimgdiv {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 342px;
    padding: 60px;


}

.climetsaysyimgdiv img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.climetsaysyimgdivbgimhhj{
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-radius: 50%;
    padding: 13px;
}

.clinetsttsaytsxtdiv {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
}

.clinetsttsaytsxtdivpp {
    font-family: "Lato", sans-serif;
    font-size: 23px;
    color: #ccccccb5;
    line-height: 36px;
    font-weight: 400;
    margin-top: 10px;
    width: 100%;
}

.climetsaysyimgdivmain {
    width: 100%;
    max-width: 30%;
    min-width: 30%;
}

.shtrittiyadaytxtpp {
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    color: #fff;
    line-height: 31px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
}

.shtrittiyadaytxtppdesgtxtp {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: #ccccccb5;
    line-height: 26px;
    font-weight: 400;
}
.whyworkdivmaintxtpphhhdr{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #0171b1;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    margin: 10px 0px 0px 0px;
}

@media(max-width:1499px) {
    .whyworkdivmaintxtpp {
        font-size: 20px;
        line-height: 34px;
        margin-top: 12px;
    }
    .carrertxtteamhhh {
        font-size: 50px;
        line-height: 65px;
    }

    .whyworkdivmaindivflx {
        column-gap: 20px;
    }

    .whyworkdivmain {
        width: 100%;
        max-width: 32%;
        min-width: 32%;
    }

    .carrerfrmdivmaib {
        width: 100%;
        max-width: 45%;
        min-width: 45%;
    }

    .jiointxtdtnamirxrp {
        width: 100%;
        max-width: 65%;
        min-width: 65%;
    }
    .climetsaysyimgdivmain {
        width: 100%;
        max-width: 35%;
        min-width: 35%;
    }
    .clinetsttsaytsxtdiv {
        width: 100%;
        max-width: 50%;
        min-width: 50%;
    }
}

@media(max-width:1266px) {
    .carrerfrmdivmaib {
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    }

}

@media (max-width: 1199px) {
    .whyworkdivmain {
        width: 100%;
        max-width: 30%;
        min-width: 30%;
    }
    .climetsaysyimgdivmain {
        width: 100%;
        max-width: 40%;
        min-width: 40%;
    }
    .clinetsttsaytsxtdiv {
        width: 100%;
        max-width: 50%;
        min-width: 50%;
    }
    .carrerfrmdivmaib {
        width: 100%;
        max-width: 60%;
        min-width: 60%;
    }

    .carrerimgdivmain {
        width: 100%;
        max-width: 30%;
        min-width: 30%;
    }

    .carrerimgdivmainimg {
        margin-left: 84px;
    }

    .whyworkdivmaintxtpp {
        font-size: 18px;
        line-height: 30px;
        margin-top: 12px;
    }
    .carrertxtteamhhh {
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }
}

@media (max-width: 991px) {
    .whyworkdivmain {
        width: 100%;
        max-width: 48%;
        min-width: 48%
    }

    .jiointxtdtnamirxrp {
        font-size: 20px;
        line-height: 37px;
        font-weight: 400;
    }
    .healthcareswipper {
        padding: 20px 0px 0px 0px;
    }
    .clinetsttsaytsxtdivpp {
        margin-top: 20px;
        width: 100%;
    }
    .carrertxtteamhhh {
        font-size: 45px;
        line-height: 60px;
    }

    .carrerfrmdivmaibdivflx {
        flex-wrap: wrap;
    }

    .carrerfrmdivmaib {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .carrerimgdivmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .carrerimgdivmainimg img {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 0px;
    }

    .carrerimgdivmainimg {
        margin-left: 0px;
        margin-top: 30px;
    }

    .carrertxtteamhhh {

        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .workmainsection {
        padding: 40px 0px 80px 0px;
    }

    .clientsayasmain {
        position: static;
        /* background-color: #0171b1; */
        padding: 40px 50px;
        position: relative;
        margin-top: 0px;
        z-index: 0;
        margin-bottom: 0px;
    }
    .employeuimgtextdivflxsld{
         flex-wrap: wrap;
    }
    .climetsaysyimgdivmain {
        width: 100%;
        max-width: 100%;
        min-width: 10%;
    }
    .clinetsttsaytsxtdiv {
        width: 100%;
        max-width: 100%;
        min-width: 10%;
    }
    .climetsaysyimgdiv {
        height: auto;
    }
}

@media(max-width:767px) {
    .worlttstxthhh {
        font-size: 45px;
    }
    .clinetsttsaytsxtdivpp {
        font-size: 20px;
        line-height: 30px;
        margin-top: 10px;
        width: 100%;
    }

    .carrertxtteamhhh {
        font-size: 45px;
    }
 
    .workimgfrstr {
        width: 100%;
        max-width: 270px;
        min-width: 270px;
        height: 270px;
    }

    .jiointxtdtnamirxrp {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .workimgfrstrimgright {
        width: 100%;
        max-width: 160px;
        min-width: 160px;
        height: 293px;
    }

    .workimgfrstrimgright::before {
        left: -20%;
    }

    .workimgfrstr::after {
        right: -31px;
    }
}

@media (max-width: 576px) {
    .whyworkdivmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .healthcareswipper {
        padding: 0px 0px 0px 0px;
    }

    .workimgfrstrimgright {
        width: 100%;
        max-width: 130px;
        min-width: 130px;
        height: 250px;
    }

    .workimgfrstr {
        width: 100%;
        max-width: 220px;
        min-width: 220px;
        height: 236px;
    }

    .workimgfrstrimgright::before {
        left: -28%;
    }

    .workimgfrstr::after {
        top: 190px;
    }
    .clientsayasmaincontentdiv {
        background-color: #0171b1;
        width: 100%;
        max-width: 100%;
        padding: 30px;
    }
    .employeuimgtextdivflxsld {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 30px;
        padding-top: 2px;
    }
    .clinetsttsaytsxtdivpp {
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        width: 100%;
    }
    .climetsaysyimgdiv {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding: 30px;
    }

    .whyworkdivmaintxtpp {
        font-size: 23px;
        line-height: 31px;
        margin-top: 12px;
    }

    .carrertxtteamhhh {
        font-size: 40px;
    }

    .worlttstxthhh {
        font-size: 40px;
        line-height: 45px;
    }

    .workmainsection {
        padding: 20px 0px 80px 0px;
    }
    .clientsayasmain {
        padding: 20px 20px;
    }
}

@media (max-width: 480px) {
    .whyworkdivmaintxtpp {
        font-size: 18px;
        line-height: 28px;
        margin-top: 12px;
    }

    .carrertxtteamhhh {
        font-size: 27px;
        line-height: 32px;
    }

    .worlttstxthhh {
        font-size: 30px;
    }

    .workimgfrstr {
        width: 100%;
        max-width: 178px;
        min-width: 178px;
        height: 199px;
    }

    .workimgfrstrimgright {
        width: 100%;
        max-width: 100px;
        min-width: 100px;
        height: 215px;
    }

    .workimgfrstrimgright::before {
        left: -37%;
        max-width: 22px;
        min-width: 21px;
        height: 44px;
    }

    .workimgfrstr::after {

        max-width: 22px;
        min-width: 21px;
        height: 44px;
    }

    .workimgfrstr::before {
        max-width: 84px;
        min-width: 82px;
        height: 40px;
        bottom: -53px;
    }

    .jiointxtdtnamirxrp {
        font-size: 17px;
        line-height: 26px;
        font-weight: 400;
    }
}