/****industrialsolution_banner***/
.industrialsolution_banner {
    width: 100%;
    height: auto;
    position: relative;
}

.industrialsolution_banner .bgimage_ {
    width: 100%;
    height: 750px;
    overflow: hidden;
    position: relative;
}
.industrialsolution_banner .bgimage_::after{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: #0000008a;
}


.industrialsolution_banner .bgimage_ img {
    width: 100%;
    height: 100%;
    position: relative;
}

.industrialsolution_banner .banner_wrap {
    width: 100%;
    height: 100%;
}

.industrialconentdiv {
    width: 100%;
    height: auto;
    padding: 0px 0;
}

.industrialsolution_banner .sec_headingbannertset {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 9;
}

/******SectorWeServer_sec****/

.SectorWeServer_sec {
    width: 100%;
    height: auto;
    padding: 40px 0 0px 0px;
}

.SectorWeServer_sec .sec_heading {
    text-align: center;
    font-size: 50px;
    font-weight: 200;
    text-align: left;
    line-height: 79px;
    color: #000;
    padding: 0;
    margin-bottom: 0px;
    font-family: 'DiastemaBoldItalic';
    width: 100%;
    max-width: 100%;
}

.SectorWeServer_sec .seclist {
    width: 100%;
    height: auto;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    padding-top: 40px;
}

.softwaresevices_main .sec_heading {
    text-align: center;
    font-size: 70px;
    font-weight: 200;
    text-align: left;
    line-height: 60px;
    color: #000;
    padding: 0;
    margin-bottom: 0px;
    font-family: Salita;
    width: 100%;
    max-width: 100%;
}

.aipoewerimgmainmainimjhh {
    width: 100%;
    max-width: 40%;
}

.aipoewerimgmain {
    width: 100%;
    height: 524px;
    margin-left: 117px;
}

.healthcares_divnewadd {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.healthcares_divnewadd:nth-of-type(even) {
    flex-direction: row-reverse;
}

.healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img {
    width: 100%;
    max-width: 100%;
    height: 480px;
    /* margin-left: 113px; */
    margin-left: -41px;
    
}

.healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img img {
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 100px;
    object-fit: cover;
    border-bottom-left-radius: 0px;
}

.healthcares_divnewadd:nth-of-type(even) .healthcares_div_left {
    margin-left: 70px;
}

.aipoewerimgmain img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-left-radius: 100px;

}

.choosedivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
}

.choosedivleft {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
}

.choosedivright {
    width: 100%;
    max-width: 48%;
    min-width: 48%;
}

.choosedivrightimgmain {
    width: 100%;
    height: 400px;

}

.choosedivrightimgmain img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.gebnerativeseraipoewerimgdivflx {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    column-gap: 40px;
    padding-top: 30px;
}

.SectorWeServer_sec .seclist li {
    width: 100%;
    max-width: 50%;
    height: auto;
    list-style: none;
    margin-bottom: 20px;
}


.SectorWeServer_sec .seclist li a {
    font-size: 30px;
    font-weight: 500;
    color: #000;
}







.healthcare_main {
    width: 100%;
    height: auto;
    padding: 40px 0px 0px 0px;
}

.softwaresevices_main {
    width: 100%;
    height: auto;
    padding: 40px 0px 0px 0px;
}

.assisttxtpp {
    color: #000;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    font-family: Salita;
}

.assisttxtppmainpp {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    line-height: 45px;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.assisttxtppmainpptestpp {
    color: #000;
    font-size: 25px;
    font-weight: 800;
    line-height: 35px;
    padding-top: 20px;
    font-family: Salita;
    width: 100%;
    max-width: 100%;
}

.dsitrrstpptextppp {
    color: #000;
    font-size: 21px;
    font-weight: 300;
    line-height: 33px;
    /* padding-top: 20px; */
    font-family: "Montserrat", sans-serif;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.listsftwareoldivol {
    list-style: lower-roman;
    padding-inline-start: 20px;
}

.listsftwareoldivlitxtpp {
    color: #000;
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    max-width: 100%;
    margin-left: 20px;
}

.softeawrealexaleftrightdivflx {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
    padding: 20px 0px;
    flex-wrap: wrap;
    row-gap: 20px;
}

.softeawrealexaledivleft {
    width: 100%;
    max-width: 48%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px 15px;
    border-radius: 10px;
}

.softeawrealexaledivright {
    width: 100%;
    max-width: 48%;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 10px 15px;
    border-radius: 10px;
}

.blockchainimgammain {
    width: 100%;
    max-width: 34%;
    height: 265px;
}

.blockchainimgammain img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.assisttxtppmainpptestppboxghh {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    padding-top: 20px;
    font-family: Salita;
    width: 100%;
    max-width: 100%;
}

.listsftwareoldivlitxtppboxtxt {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;

}

/* .healthcares_div:nth-last-child(even) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    flex-direction: row-reverse;
} */
.healthcares_div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}

.healthcares_div_left {
    width: 100%;
    max-width: 65%;
    min-width: 65%;
}

.softwaredivspclbgdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    /* padding:50px 0px 30px 0px */
}

.softwaredivspclbgdivfirstleft {
    width: 100%;
    max-width: 32%;
}

.softwaredivspclbgdivfirstrigh {
    width: 100%;
    max-width: 32%;
}

.softwaredivspclbgdivfirstleftbg {
    background-color: #fff;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
    width: 100%;
    padding: 20px;
}

.fullstxttsrrtppp {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    padding-top: 20px;
    font-family: Salita;
}

.fullstxttsrrtpppshrtdexc {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
}

.healthcares_div_right {
    width: 100%;
    max-width: 35%;
    min-width: 35%;
}
.setydyydhshjugj{
  list-style-type: none;
  padding-top: 20px;
}
.setydyydhshjugjli{
    padding-bottom: 10px;
}
.hlthcaretetx_heading_text {
    color: #000;
    font-size: 35px;
    font-weight: 600;
    line-height: 73px;
    font-size: 61px;
    /* font-family: Salita; */
    font-family: AbolitionTestRegular;
    letter-spacing: 1px;
}
.setydyydhshjugjliaa{
     font: 600 15px/20px "Lato", sans-serif;
     color: #000;
}
.akjjdjyuhg{
    font: 400 20px/25px "Lato", sans-serif; 
    color: #000;
    margin-bottom: 15px;
}

.hlthcaretetx_para_text {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    line-height: 45px;
    padding-top: 20px;
    font-family: "Lato", sans-serif;
    width: 100%;
    max-width: 100%;
}
.hlthcaretetx_para_text_new{
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    max-width: 100%; 
 
}
.hlthcaretetx_para_text_new span{
    font-weight: 500
}
.healthcares_div_img {
    width: 100%;
    max-width: 100%;
    height: 480px;
    margin-left: 113px;

}

.softwareautomaitiondiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-direction: column;
    width: 100%;
    max-width: 38%;
}

.softwareautomaitiondivinto {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
    padding: 10px
}

.healthcares_div_img img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 100px;
    object-fit: cover;
}

.softwareimgmationdivimg {
    width: 100%;
    max-width: 40%;
}

.softwareimgmationdivimgmain {
    width: 100%;
    height: 500px;
    padding-top: 50px;
}

.softwareimgmationdivimgmain img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.softwaredivspclbgdivnewcloud {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 40px;
}

.softwareautomaitiondivdivflx {
    display: flex;
    justify-content: space-between;
    /* align-items: baseline; */
    column-gap: 20px;
    padding: 50px 0px 20px 0px;
}

.healthcareswipper {
    padding: 10px 0px 0px 0px;
}

.healthcare_divsld_img {
    width: 100%;
    max-width: 100%;
    height: 400px;
    border-radius: 40px;
    overflow: hidden;
}

.healthcarepractice {
    width: 100%;
    height: auto;
    padding: 40px 0px 0px 0px;
}

.disprocremnchamdivtopbottommix {
    width: 100%;
    max-width: 24%;
    min-width: 24%;
}
.healthcares_div_imgmainalada {
    width: 100%;
    max-width: 100%;
    height: 300px;
    margin-left: 113px;
}
.healthcares_div_imgmainalada img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 100px;
    object-fit: cover;
}

.healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_imgmainalada {
    width: 100%;
    max-width: 100%;
    height: 300px;
    /* margin-left: 113px; */
    margin-left: -41px;
}
.healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_imgmainalada img {
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 100px;
    object-fit: cover;
    border-bottom-left-radius: 0px;
}


.ggpappadinbtm {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    color: #000;
    padding: 0;
    margin: 20px 0px 0px 0px;
    font-family: "Lato", sans-serif;
}
.paggacompleijkenndul{
    margin: 20px 0px;
}
.paggacompleijkenndlitxtpp{
    text-decoration: none;
    font-size: 22px;
    font-weight: 400;
    /* font-family: "Roboto", sans-serif; */
    line-height: 30px;
    color: #000;
    font-family: "Montserrat", sans-serif;
   
}
.paggacompleijkenndli{
    margin-bottom: 10px;
}
.paggacompleijkenndul{
    list-style-type: lower-roman;
}
.disprocremnchamdivtopbottobg {
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    overflow: hidden;
}

.assitstxtpphhhh {
    font-size: 18px;
    color: #000;
    font-family: salita;
    line-height: 25px;
    margin-bottom: 10px;
}

.assitstxtpphhhhparaa {
    color: #000;
    font-size: 15px;
    font-weight: 300;
    line-height: 22px;
    /* padding-top: 20px; */
    font-family: "Montserrat", sans-serif;
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    height: 115px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;

}

.disprocremnchamdivtopbottomdiv {
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}

.softeawrealexalefmaindivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    padding-top: 20px;
    padding-bottom: 40px;
    flex-wrap: wrap;
    row-gap: 20px;
}

.disprocremnchamdivtopbottommixflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;
    padding-top: 20px;
    padding-bottom: 40px;
    flex-wrap: wrap;
    row-gap: 20px;
}

.disprocremnchamdivtop {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 200px;
}

.disprocremnchamdivbottom {
    padding: 10px;
}

.disprocremnchamdivtop img {
    width: 100%;
    height: 100%;
}

.healthcarepracticeflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.healthcarepracticedibimg {
    width: 100%;
    max-width: 32%;
    min-width: 32%;
}

.healthcarepracticedibimgmain {
    width: 100%;
    max-width: 100%;
    height: 400px;
    border-radius: 40px;
    overflow: hidden;
}

.healthcarepracticedibimgmain img {
    width: 100%;
    max-width: 100%;
    height: 400px;
    border-radius: 40px;
    object-fit: cover;
}

.healthcare_divsld_img img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

.healthcareswipper .swiper-slide-next {
    padding-top: 91px;
}

.slide_text_dtls_pp {
    font-size: 30px;
    color: #000;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    line-height: 33px;
    text-align: left;
    margin-top: 25px;
}

.arrowdivrignt {
    margin-left: auto;
}

.servicetextarrowduvflx {
    display: flex;
    justify-content: flex-start;
    align-items: end;
    margin-bottom: 25px;
}

.softwaralesxttstmidlle {
    color: #000;
    font-size: 35px;
    font-weight: 600;
    line-height: 45px;
    font-family: Salita;
    text-align: center;
}

.softwaralesxttstmidlletxthh {
    color: #000;
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
    font-family: "Montserrat", sans-serif;
}

.gebnerativesericevesdivflx {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 30px;
    width: 100%;
    max-width: 60%;
    flex-direction: column;
}

.enginewwringcontenmaindivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.enginewwringcontenmainlewft {
    width: 100%;
    max-width: 50%;
    padding-top: 40px;
}

.gebnerativesericevesdivflxmain {
    padding: 50px 0px 0px 0px;
}

.gebnerativesericevesdivleft {
    width: 100%;
    max-width: 100%;
}

.gebnerativesericevesdivright {
    width: 100%;
    max-width: 100%;
}

.gebnerativesericevesdivleftbg {
    width: 100%;
    background-color: #cccccc63;
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.15), 1px 2px 5px 0px rgba(0, 0, 0, 0.15) inset;
    padding: 20px;
}

.softwaredivspclbgconsultingright {
    width: 100%;
    max-width: 48%;
}

.softwaredivspclbgconsultingleft {
    width: 100%;
    max-width: 48%;
}

@media(max-width:1499px) {
    .assisttxtppmainpptestppboxghh {
        font-size: 17px;
        font-weight: 600;
        line-height: 22px;
        padding-top: 20px;

    }

    .listsftwareoldivlitxtppboxtxt {
        color: #000;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        padding-top: 14px;
        font-family: "Montserrat", sans-serif;
    }

    .healthcares_div_img {
        margin-left: 90px;
    }

    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img {
        margin-left: -17px;
    }
}


@media(max-width:1199px) {
    .slide_text_dtls_pp {
        font-size: 24px;
        line-height: 34px;
        margin-top: 10px;
    }

    .hlthcaretetx_heading_text {
        line-height: 65px;
        font-size: 53px;
    }

    .hlthcaretetx_para_text {
        font-size: 22px;
        line-height: 42px;
    }

    .softwaredivspclbgdivfirstleft {
        width: 100%;
        max-width: 48%;
    }
    .healthcares_div_img {
        margin-left: 60px;
    }
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_left {
        margin-left: 51px;
    }

    .aipoewerimgmain {
        width: 100%;
        height: 524px;
        margin-left: 87px;
    }

    .gebnerativesericevesdivleft {
        order: 2;
    }

    .softwaredivspclbgdivfirstrigh {
        width: 100%;
        max-width: 48%;
    }

    .fullstxttsrrtppp {
        color: #000;
        font-size: 17px;
        font-weight: 600;
        line-height: 30px;
        padding-top: 20px;
        font-family: Salita;
    }

    .SectorWeServer_sec .sec_heading {
        text-align: left;
        font-size: 43px;
        font-weight: 200;
        line-height: 62px;
    }

}


@media(max-width:991px) {
    .healthcares_div {
        flex-wrap: wrap;
    }
    .healthcares_div_imgmainalada {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-left: 0px;
      

    }
    .SectorWeServer_sec .sec_heading {
        text-align: left;
        font-size: 36px;
        font-weight: 200;
        line-height: 45px;
    }
   
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_imgmainalada {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-left: 0px;
        border-bottom-right-radius: 40px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
  
    
    }
    .healthcares_div_imgmainalada img{
        border-bottom-right-radius: 40px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
      
    }
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_imgmainalada img {
   
        border-bottom-right-radius: 40px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
    
    }

    .softwareautomaitiondivdivflx {
        flex-wrap: wrap;
        row-gap: 20px;

    }
    .healthcares_divnewadd{
        flex-wrap: wrap;
    }
    .healthcares_div_img img {
        border-bottom-right-radius: 40px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        margin-top: 30px;
    }
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img img {
        border-bottom-right-radius: 40px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border-bottom-left-radius: 40px;
        margin-top: 30px;
    }
    /* .healthcares_divnewadd:nth-of-type(even){
        padding-bottom: 40px;
    } */
    .choosedivflx{
        flex-wrap: wrap;
    }
    .choosedivleft {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .choosedivright {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .choosedivrightimgmain {
        width: 100%;
        height: auto;
        margin-top: 30px;
    }

    .gebnerativesericevesdivflx {
        flex-wrap: wrap;
        row-gap: 30px;
        width: 100%;
        max-width: 100%;
    }

    .aipoewerimgmainmainimjhh {
        width: 100%;
        max-width: 100%;
    }

    .aipoewerimgmain {
        width: 100%;
        height: 524px;
        margin-left: 0px;
        padding-top: 30px;
    }

    .gebnerativeseraipoewerimgdivflx {
        flex-wrap: wrap;
    }

    .gebnerativesericevesdivleft {
        width: 100%;
        max-width: 100%;
    }

    .gebnerativesericevesdivright {
        width: 100%;
        max-width: 100%;
    }

    .softwareautomaitiondiv {

        width: 100%;
        max-width: 55%;
    }

    

    .healthcares_div_left {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .softwaredivspclbgdiv {
        flex-direction: row-reverse;
    }

    .healthcares_div_right {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .healthcares_div_img {
        height: auto;
        margin-left: 0px;
    }

    .hlthcaretetx_para_text {
        width: 100%;
        max-width: 100%;
    }

    .healthcare_divsld_img {
        height: 275px;
    }

    .slide_text_dtls_pp {
        font-size: 19px;
        line-height: 24px;
    }

    .healthcare_main {
        width: 100%;
        height: auto;
        padding: 40px 0px 0px 0px;
    }

    .SectorWeServer_sec {
        width: 100%;
        height: auto;
        padding: 40px 0px 0px 0px;
    }

    .healthcare_main .swiper-slide {
        margin-right: 26px !important;
    }

    .softeawrealexaledivleft {
        width: 100%;
        max-width: 100%;

    }

    .softeawrealexaledivright {
        width: 100%;
        max-width: 100%;
    }

    .blockchainimgammain {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .softwareimgmationdivimg {
        order: 1;
    }

    .softwareautomaitiondiv {
        order: 2;
    }
}

@media (max-width: 767px) {
    .healthcare_divsld_img {
        height: 205px;
    }

    .softwaredivspclbgconsultingleft {
        width: 100%;
        max-width: 100%;
    }
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img {
        margin-left: 0px;
    }

    .softwaredivspclbgconsultingright {
        width: 100%;
        max-width: 100%;
    }

    .slide_text_dtls_pp {
        font-size: 16px;
        line-height: 20px;
    }

    .industrialsolution_banner .bgimage_ {
        height: 400px;
    }

    .softwaredivspclbgdiv {

        padding: 0px 0px 30px 0px;
    }

    .gebnerativesericevesdivflxmain {
        padding: 20px 0px 30px 0px;
    }

    .softwareautomaitiondiv {
        width: 100%;
        max-width: 100%;
    }

    .softwareimgmationdivimg {
        width: 100%;
        max-width: 100%;
    }

    .softwareimgmationdivimgmain {
        width: 100%;
        height: 500px;
        padding-top: 0px;
    }

    .gebnerativesericevesdivflx {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px 0px 0px;
    }

    .hlthcaretetx_para_text {

        font-size: 21px;
        line-height: 38px;
    }

    .fullstxttsrrtpppshrtdexc {
        color: #000;
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
        padding-top: 10px;
        font-family: "Montserrat", sans-serif;
    }

    .industrialsolution_banner .sec_heading {
        font-size: 32px;
        line-height: 45px;
        width: 100%;
    }

    .healthcarepracticedibimgmain {
        width: 100%;
        max-width: 100%;
        height: 200px;
        border-radius: 40px;
        overflow: hidden;
    }


    .SectorWeServer_sec .seclist li {
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 0px;
        margin-bottom: 20px;
    }

    .SectorWeServer_sec .sec_heading {
        text-align: center;
        font-size: 28px;
        font-weight: 200;
        text-align: left;
        line-height: 29px;
        color: #000;
        width: 100%;
        max-width: 100%;
    }

    .SectorWeServer_sec .seclist li a {
        font-size: 25px;
    }

    /* .SectorWeServer_sec {
        padding: 30px 0;
    } */

    .hlthcaretetx_heading_text {
        font-size: 45px;
        line-height: 55px;
    }

    .assisttxtppmainpp {
        color: #000;
        font-size: 20px;
        font-weight: 300;
        line-height: 35px;
        padding-top: 15px;
        margin-bottom: 15px;
    }

    .dsitrrstpptextppp {
        color: #000;
        font-size: 20px;
        font-weight: 300;
        line-height: 35px;
        padding-top: 15px;
        margin-bottom: 15px;
    }

    .assisttxtppmainpptestpp {
        font-size: 20px;
        line-height: 30px;
        padding-top: 15px;
    }


}

@media (max-width: 576px) {
    .healthcare_divsld_img {
        height: 180px;
    }
    .healthcares_div_img img {
       margin-top: 0px;
    }

    .softwaredivspclbgdivfirstleft {
        width: 100%;
        max-width: 100%;
    }
    .SectorWeServer_sec .sec_heading {
        text-align: center;
        font-size: 22px;
        font-weight: 200;
        text-align: left;
        line-height: 32px;
        color: #000;
        width: 100%;
        max-width: 100%;
    }
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img {
       height: auto;
    }

    .softwaredivspclbgdivfirstrigh {
        width: 100%;
        max-width: 100%;
    }

    .hlthcaretetx_para_text {
        width: 100%;
        max-width: 100%;
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
        padding-top: 8px;
    }

    .listsftwareoldivlitxtpp {
        color: #000;
        font-size: 15px;
        line-height: 20px;
        margin-left: 8px;

    }

    .listsftwareoldivol {
        list-style: lower-roman;
        padding-inline-start: 8px;
    }

    .sec_headingbannertset {
        font-size: 37px;
        font-weight: 200;
        text-align: left;
        line-height: 46px;
        color: #fff;
        padding: 0;
        margin-bottom: 0px;
        
    }

    .healthcares_div_right {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-top: 20px;
    }

    .hlthcaretetx_heading_text {
        color: #000;
        font-size: 30px;
        font-weight: 600;
        line-height: 39px;
    }


    .assisttxtppmainpptestpp {
        font-size: 18px;
        line-height: 16px;
        padding-top: 5px;
    }

    .assisttxtppmainpp {
        color: #000;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        padding-top: 15px;
        margin-bottom: 15px;
    }

    .dsitrrstpptextppp {
        color: #000;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
        padding-top: 15px;
        margin-bottom: 15px;
    }

    .healthcare_main {
        width: 100%;
        height: auto;
        padding: 20px 0px 0px 0px;
    }

    .assisttxtpp {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        font-family: Salita;
    }

    .industrialsolution_banner .bgimage_ {
        height: auto;
    }

    .industrialsolution_banner .sec_heading {
        font-size: 24px;
        line-height: 35px;
    }

    .SectorWeServer_sec .seclist li a {
        font-size: 16px;
    }

    .softwareautomaitiondivdivflx {
        padding: 30px 0px 20px 0px;
    }
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img img {
        margin-top: 0px;
    }
    .healthcares_divnewadd:nth-of-type(even) {
        padding-bottom: 0px;
    }
}

@media (max-width: 480px) {
    .healthcare_divsld_img {
        height: 180px;
    }
    .SectorWeServer_sec .sec_heading {
        text-align: center;
        font-size: 19px;
        font-weight: 200;
        text-align: left;
        line-height: 32px;
        color: #000;
        width: 100%;
        max-width: 100%;
    }
    .healthcares_divnewadd {
        margin-bottom: 25px;
    }
    .healthcares_divnewadd:nth-of-type(even) .healthcares_div_right .healthcares_div_img img {
        border-bottom-right-radius: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .healthcares_div_img img {
        border-bottom-right-radius: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }

    .slide_text_dtls_pp {
        font-size: 19px;
        line-height: 24px;
    }

    .healthcarepracticeflx {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .healthcarepracticedibimg {
        width: 100%;
        max-width: 48%;
        min-width: 48%;
    }

    .industrialsolution_banner .sec_heading {
        font-size: 18px;
        line-height: 30px;
    }

    .hlthcaretetx_heading_text {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
    }

}

@media(max-width:420px) {
    .healthcareswipper .swiper-slide-next {
        padding-top: 0px;
    }
}