.achebvennemeygsttsection {
    width: 100%;
    padding: 50px 0px;
    width: 100%;
    height: 650px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 20px;
    margin-bottom: 20px;

}

/* .innovatioinbgimg {
   
} */
.innovationndivoimmggpading {
    margin: 48px 117px;
}

.iinovvationntxtppop {
    color: #000;
    font-size: 78px;
    font-family: Salita;
    font-weight: 700;
    line-height: 76px;
    margin-bottom: 12px;
}
.certtaiinitxtppp{
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    color: #fff;
    line-height: 25px;
    font-weight: 200;
    width: 100%;
    max-width: 70%;
    min-width: 70%;
}
.certtsiisfoimdivflx{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
    padding-top: 28px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 60%;
    min-width: 60%;

}
.certtsiisfoimdivimgmain{
    width: 100%;
    max-width: 180px;
    min-width: 180px;
    height: 125px;
    margin-bottom: 35px;
}
.certtsiisfoimdivimgmain img{
    width: 100%;
    height: 100%;
}
@media(max-width:1270px){
    .certtsiisfoimdivflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 30px;
        padding-top: 28px;
        flex-wrap: wrap;
        width: 100%;
        max-width: 80%;
        min-width: 80%;
    }
}
@media(max-width:1199px){
    .innovationndivoimmggpading {
        margin: 54px 37px;
    }
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 184px;
        min-width: 184px;
        height: 100px;
    }
    .iinovvationntxtppop {
        color: #000;
        font-size: 60px;
        font-family: Salita;
        font-weight: 700;
        line-height: 76px;
        margin-bottom: 30px;
    }
    .certtaiinitxtppp {
        font-family: "Montserrat", sans-serif;
        font-size: 29px;
        color: #fff;
        line-height: 36px;
        font-weight: 200;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
}

@media(max-width:991px){
    .innovatioinbgimg {
        width: 100%;
        height: 792px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .innovationndivoimmggpading {
        margin: 65px 15px;
    }
    .certtsiisfoimdivflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 30px;
        padding-top: 28px;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 190px;
        min-width: 190px;
        height: 104px;
    }
    
}
@media(max-width:767px){
    .certtsiisfoimdivflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 19px;
        /* padding-top: 40px; */
    }
    .iinovvationntxtppop {
        color: #000;
        font-size: 40px;
        font-family: Salita;
        font-weight: 700;
        line-height: 50px;
        margin-bottom: 30px;
    }
    .certtaiinitxtppp {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        color: #fff;
        line-height: 38px;
    }
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 156px;
        min-width: 156px;
        height: 88px;
        margin-bottom: 22px;
    }
    .innovationndivoimmggpading {
        margin: 0px 121px;
    }
    .achebvennemeygsttsection {
        width: 100%;
        height: auto;
        padding: 30px 0px;
        width: 100%;
        height: 317px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media(max-width:672px){
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 115px;
        min-width: 115px;
        height: 88px;
        margin-bottom: 22px;
    }
}
@media(max-width:576px){
    .iinovvationntxtppop {
        color: #000;
        font-size: 40px;
        font-family: Salita;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 2px;
    }
    .certtaiinitxtppp {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        color: #fff;
        line-height: 31px;
    }
    /* .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 80px;
        min-width: 80px;
        height: 40px;
    } */
    .innovationndivoimmggpading {
        margin: 42px 12px;
    }
    
}
@media(max-width:576px){
    .certtsiisfoimdivflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 15px;
        padding-top: 20px;
    }
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 100px;
        min-width: 100px;
        height: 55px;
        margin-bottom: 20px;
    }
}
@media(max-width:480px){
    .certtsiisfoimdivflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 17px;
        padding-top: 25px;
    }
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 73px;
        min-width: 73px;
        height: 55px;
        margin-bottom: 20px;
    }
}

@media(max-width:420px){
    .iinovvationntxtppop {
        color: #000;
        font-size: 27px;
        font-family: Salita;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 2px;
    }
    /* .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 80px;
        min-width: 80px;
        height: 55px;
        margin-bottom: 20px;
    } */
    .innovationndivoimmggpading {
        margin: 42px 12px;
    }
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 60px;
        min-width: 60px;
        height: 57px;
        margin-bottom: 2px;
    }
    .certtsiisfoimdivflx {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 9px;
        padding-top: 20px;
    }
    .certtaiinitxtppp {
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        color: #fff;
        line-height: 25px;
    }
    
}
@media (max-width: 360px) {
    .certtsiisfoimdivimgmain {
        width: 100%;
        max-width: 55px;
        min-width: 55px;
        height: 57px;
        margin-bottom: 2px;
    }
}