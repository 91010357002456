* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure,
label {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  width: 100%;
  height: auto;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  color: #000;
}

.cus_container {
  width: 100%;
  max-width: 92%;
  margin: 0 auto;
}


/* Start Header Section */
.headermain {
  width: 100%;
  height: 90px;
  padding: 6px 0px;
  background-color: transparent;
  /* box-shadow: 0px 0px 2px 0px #ccc; */
  position: relative;
  transition: .3s all ease-in;
}


.headermain.sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  z-index: 10;
  transition: .3s all ease-in;
  animation: fadeInDown 800ms;
  -webkit-backface-visibility: hidden;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.logomenusubmenudivflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hedermenudiv{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}
.mainlogodivimg {
  width: 100%;
  max-width: 110px;
  min-width: 110px;
  height: auto;
}

.mainlogodivimg img {
  width: 100%;
  height: 100%;
}

.mainlogodivimg video {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.headermain.sticky .mainlogodivimg video {
  display: none;
}

.headermain.sticky .mainlogodivimg img {
  display: inline-block;
}


.headermenulistul {
  list-style-type: none;
  display: flex;
  height: 100%;
}

.headermenulistli {
  text-decoration: none;
  /* display: inline-block; */
  margin-right: 60px;
  position: relative;
}

.listlnktxt {
  text-decoration: none;
  font-size: 22px;
  font-weight: 400;
  line-height: 64px;
  color: #000;
  font-family:  DiastemaBold;
}

.headermain.sticky .listlnktxt {
  color: #000;
}

.listlnktxt:hover {
  text-decoration: none;
  /* color: #F8B84F; */
  text-decoration: underline;
  color: #000;
}

.getquatebtn {
  color: #ffffff;
  font-size: 15px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  border: none;
}

.getquatebtn:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
  border: none;
}

.headerrightmain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 25px;
}

.sidebaropen i {
  display: none;
  color: #000000;
  font-size: 18px;
  cursor: pointer;
}

.headermain.sticky .sidebaropen i {
  color: #2993EC;
}

.cartdivicon {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartdivicon i {
  color: #fff;
  font-size: 12px;
}

.cartdivicondot {
  position: absolute;
  top: -4px;
  right: 0px;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #F44336;
}

.srchdivicon i {
  font-size: 20px;
  color: #ffffff;
}

.plusicon i {
  font-size: 15px;
  margin-left: 10px;

}
.listlnktxtsidebar.active{
  color: #000;
  text-decoration: none;
} 
.listlnktxtsidebarttsyypp{
  margin-left: 20px;
  font: 400 17px / 22px DiastemaBold;
  color: #000;
  padding-bottom: 5px;
}
.submenulistdivli{
  padding-bottom: 5px;
  padding-top: 5px;
}
.teamsubmenusidebatsdpbk{
  position: relative;
}
.submenulist {
  
  position: absolute; 
  
  list-style: none;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 60px);
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 20px 30px 10px;
  width: auto;
  display: none;
  z-index: 9;
  width: max-content;
  border-radius: 0 0 10px 10px;
  transition: 0.3s all ease-in-out;
}
.lnklistheder {
  color: rgba(43, 0, 96, 1);
  transition: color .3s ease-in-out;
  height: 100%;
  display: flex;
  align-items: center;
  font-family:  DiastemaBold;
  margin: 0px 0px 10px;
}
.listlnktxtsidebarsidebsubn {
  margin-left: 35px;
  font: 400 17px / 22px DiastemaBold;
  color: #000;
  padding-bottom: 5px;
}
.sadSADae{
  padding-bottom: 10px;
  padding-top: 10px;
}
.lnklistheder{
  position: relative;
}

/* Styling for the team submenu */
.teamsubmenu {
  position: absolute;
  list-style: none;
  background: rgba(255, 255, 255, 1);
  top: 0;
  left: 100%;
  transform: translate(-28%, 60px);
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 20px 30px 10px;
  width: auto;
  display: none;
  z-index: 1;
  width: max-content;
  border-radius: 0 0 10px 10px;
  transition: 0.3s all ease-in-out;

}
.teamsubmenu .lnklisthedersubsublist:hover{
  text-decoration: underline;
}
.lnklisthedersubsublist {
  color: rgba(43, 0, 96, 1);
  transition: color .3s ease-in-out;
  height: 100%;
  display: flex;
  align-items: center;
  font-family:  DiastemaBold;
  margin: 0px 0px 10px;
  text-decoration: none;
}

.submenulistdivli:hover .teamsubmenu {
  display: block; 
}

/* Optional: Add some hover effects */
.lnklistheder:hover {
  background-color: #ddd;
}


/* .submenulistdivli {
  text-align: center;
} */



.submenulistdivli:hover .lnklistheder {
  color: rgba(43, 0, 96, 1);
  text-decoration: underline;
}

.lnklistheder:hover {
  text-decoration: none;
}

.headermenulistul .headermenulistli:hover>.submenulist {
 
  display: block;
  transition: 0.3s all ease-in-out;
}
.footerdivmainrightimg{
  width: 100%;
  height: 420px;
}
.footerdivmainrightimg video{
  width: 100%;
  height: 100%;
}

.headermenulistli:last-child {
  margin-right: 0px;
}

/* End Header Section */


/* Sidebar Start */
.sidebardiv {
  width: 100%;
  max-width: 300px;
  /* background: rgb(35 17 9 / 86%); */
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  display: none;
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 0px 0px 2px 0px #ccc;
}

.sidetop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px 8px 15px;
  border-bottom: 1px solid #ccc;
}

.frtsalonlogo {
  width: 100%;
  max-width: 50px;
  min-width: 50px;
  height: auto;
}

.frtsalonlogo video {
  width: 100%;
  height: 100%;
}

.frtsalonlogo img {
  width: 100%;
  height: 100%;
}

.crosssideiocn i {
  color: #000;
  font-size: 18px;
}

.crosssideiocn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #000;
}

.ftrmenulistli {
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px;
}

.submenulistdivliftrside {
  padding: 10px 15px 0px 15px;
  list-style-type: none;
}

.submenulistftr {
  position: relative;
  background: transparent;
  width: 100%;
  top: 100%;
  opacity: 0;
  z-index: 9;
  visibility: hidden;
  display: none;
  transform-origin: top;
  transform: scaleY(0);
}

.submenulistftrnew {
  position: relative;
  background: transparent;
  width: 100%;
  top: 100%;
  opacity: 1;
  z-index: 9;
  visibility: visible;
  display: block;
  transform: none;
  visibility: visible;
  transition: all 0.5s ease;
}

.submenulistdivliftr {
  padding: 10px 15px;
  list-style-type: none;
}

.listlnktxtftr {
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  color: #000;
}

.submenulistdivliftr:first-child {
  padding: 10px 15px 0px 15px;
  list-style-type: none;
}

/* Sidebar End */

/*  */
.footersectionmain {
  width: 100%;
  background-color: #fff;
  max-width: 100%;
  padding: 50px 30px;
  /* border-top: 1px solid #ccc; */
  margin-top: 90px;
  box-shadow: 0px 0px 5px 0px #ccc;
}

.footerdivmainflx {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  column-gap: 100px;
}

.headermenulistulsidebar {
  list-style-type: none;
  display: flex;
  flex-direction: column;

}

.listlnktxtsider {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
  font-family: "Montserrat", sans-serif;
}

.ledingtthewaytxtppp {
  color: #000;
  font-family: AbolitionTestRegular;
  font-size: 60px;
  font-size: 70px;
  line-height: 79px;
  font-weight: 400;
  margin-bottom: 50px;
  font-family: AbolitionTestRegular;
}

.phoneicontxtppp {
  color: #000;
  font-family: "Lato", sans-serif;
  font-size: 27px;
  line-height: 35px;
  font-weight: 400;
  margin-bottom: 20px;
}

.phoneicontxtppp span {
  color: #000;
  font-size: 18px;
  margin-right: 20px;
}

.unitexttxxttppp {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  color: #000;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 5px;
}

.uniytetstdsttraimgdoiv {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.indiausaimggsi {
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  height: 32px;
  margin-left: 10px;
}

.indiausaimggsi img {
  width: 100%;
  height: 100%;
}

.Highwaytxttppp {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  color: #000;
  line-height: 28px;
  width: 100%;
  font-weight: 400;
  max-width: 64%;
  min-width: 64%;
}

.uniytetstdsttraimgdoivmain {
  margin-bottom: 20px;
}

.footerdivmainleft {
  width: 100%;
  max-width: 48%;
  min-width: 48%;
}

.footerdivmainright {
  width: 100%;
  max-width: 48%;
  min-width: 48%;
}

.footerdivmainrightimg {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 377px;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 10px;
  border-radius: 10px;
}

.gfooterdivlinklinvlnk {
  text-decoration: none;
  color: #000;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  line-height: 38px;
  font-weight: 600;
}

.gfooterdivlinklinvlnk:hover {
  text-decoration: none;
  color: #000;
}

.gfooterdivlinkul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  column-gap: 36px;
  padding-top: 49px;
  flex-wrap: wrap;
  row-gap: 20px;
}

.footerdivmainrightimg img {
  width: 100%;
  height: 100%;
}

.cyttgstfoittetxtpp {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  color: #000;
  line-height: 28px;
  width: 100%;
  font-weight: 400;
  text-align: center;
  margin-top: 50px;
}

.compliance-span{
  color: blue;
}

.security-span{
  color: blue;
}
.ai-span{
  color: blue;
}
.team-span{
  color: blue;
}
.foundertxttpp{
  margin-bottom: 40px !important;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  font-size: 40px !important;
}

/****************/
/**Responsive ***/
@media(max-width:1499px) {
  .listlnktxt {
    font-size: 18px;
  }


  .gfooterdivlinkul {
    column-gap: 27px;
    padding-top: 20px;
  }

  .gfooterdivlinklinvlnk {
    font-size: 19px;
  }

  .gfooterdivlinkul {
    column-gap: 18px;
    padding-top: 20px;
  }
  .ledingtthewaytxtppp {
    color: #000;
    font-size: 80px;
}

  .gfooterdivlinklinvlnk {
    font-size: 17px;
  }

  .headermenulistli {
    margin-right: 50px;
  }
}

@media(max-width:1399px){
  .ledingtthewaytxtppp {
    color: #000;
    font-size: 76px;
}
}

  @media(max-width:1215px) {
    .ledingtthewaytxtppp {
    color: #000;
    font-size: 68px;
  }
}

@media(max-width:1199px) {
  .headermenulistli {
    margin-right: 25px;
  }
  .ledingtthewaytxtppp {
    color: #000;
    font-size: 60px;
}
}


@media(max-width:991px) {
  .headermenulistli {
    margin-right: 30px;
  }

  .listlnktxt {
    font-size: 14px;
  }

  .sidebaropen i {
    display: block;
    font-size: 32px;
  }

  .footerdivmainflx {
    flex-wrap: wrap;
  }

  .footerdivmainleft {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .footerdivmainright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .ledingtthewaytxtppp {
    margin-bottom: 30px;
    font-size: 50px;
    line-height: 50px;
  }

  .phoneicontxtppp {

    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    line-height: 35px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .unitexttxxttppp {
    font-family: "Montserrat", sans-serif;
    font-size: 26px;

    line-height: 35px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .gfooterdivlinkul {
    justify-content: flex-start;
    column-gap: 60px;
  }

  .hedermenudiv {
    display: block;
  }
.headermenulistul{
  display: none;
}
  .sidebardiv {
    display: block;
    transform: translate(-350px, 0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .listlnktxtsidebar{
    color: #000;
    font: 400 17px / 22px DiastemaBold;
    padding-bottom: 10px;
  }
  .headermenulistlibshujsus{
    padding: 10px 10px 6px 10px;
    border-bottom: 1px solid #ccc;
  }
  .submenulistsidebvatyu {
    position: relative;
    list-style-type: none;
    /* top: 0; */
}
  .headerrightmain .cartdivicon {
    display: none;
  }

  .footersectionmain {
    width: 100%;

    max-width: 100%;
    padding: 50px 0px 30px 0px;
    /* border-top: 1px solid #fff; */
    margin-top: 40px;
  }

  .headerrightmain.open .cartdivicon {
    display: flex;
  }

  .cyttgstfoittetxtpp {
    font-family: "Montserrat", sans-serif;
    font-size: 21px;
    line-height: 26px;
    width: 100%;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
  }


}

@media (max-width: 768px) {
  .cus_container {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }

  .mainlogodivimg {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: auto;
  }

  .letstdotogetherttxtpp {
    font-size: 25px;
    /* transform: skew(4deg, 359deg);
    top: -23px; */
  }

}

@media(max-width: 576px) {
  .Highwaytxttppp {
    font-size: 15px;
    line-height: 25px;
    width: 100%;
    font-weight: 400;
    max-width: 100%;
    min-width: 100%;
  }

  .gfooterdivlinklinvlnk {
    font-size: 14px;
  }

  .footerdivmainrightimg {
    height: auto;
  }

  .phoneicontxtppp {

    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .ledingtthewaytxtppp {
    font-size: 35px;
        margin-bottom: 20px;
        line-height: 39px;
  }

  .cyttgstfoittetxtpp {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
  }

  .carrergrowhmomedivright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-top: 65px;
  }

  .footersectionmain {
    width: 100%;
    max-width: 100%;
    padding: 30px 0px;
    /* border-top: 1px solid #fff; */
    margin-top: 40px;
  }

}

@media (max-width: 480px) {
  .sidebaropen i {
    font-size: 28px;
  }

  .gfooterdivlinkul {
    justify-content: center;
    column-gap: 33px;
  }

  .footersectionmain {
    padding: 20px 0px;
  }

  .gfooterdivlinklinvlnk {
    font-size: 12px;
  }

  .ledingtthewaytxtppp {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 38px;
  }

  .letstdotogetherttxtpp {
    font-size: 38px;
    /* transform: skew(4deg, 359deg);
    top: -23px; */
  }

  .carrergrowhmomedivright {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-top: 50px;
  }
}

