@font-face {
    font-family: Handmade;
    src: url(../Font/Handmade/Handmade.otf);
}

@font-face {
    font-family: DiastemaRegular;
    src: url(../Font/Diastema/Diastema\ Regular.ttf);
}

@font-face {
    font-family: DiastemaBold;
    src: url(../Font/Diastema/Diastema\ Bold.ttf);
}

@font-face {
    font-family: AbolitionTestRegular;
    src: url(../Font/AbolitionTest/AbolitionTest-Regular.otf);
}

@font-face {
    font-family: DiastemaBoldItalic;
    src: url(../Font/Diastema/Diastema\ Bold\ Italic.ttf);
}

.HomeBanner_sec {
    width: 100%;
    height: auto;
    padding: 50px 0;
}

.marfbf-22 {
    margin-bottom: 20px;
}

/* .HomeBanner_sec .cus_container {
    position: relative;
} */

.HomeBanner_sec .content_wrap {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.sec_heading {
    font-size: 98px;
    font-weight: 200;
    text-align: left;
    line-height: 60px;
    color: #000;
    padding: 0;
    margin-bottom: 0px;
    /* font-family: Salita; */
    font-family: AbolitionTestRegular;
    margin-bottom: 15px;

}

.sec_headingspcl {
    font-size: 98px;
    font-weight: 200;
    text-align: left;
    line-height: 60px;
    color: #000;
    padding: 0;
    margin-bottom: 24px;
    font-family: DiastemaBoldItalic;
    position: relative;
}

.sec_headingspcl::after {
    position: absolute;
    content: url(../Images/arrowmanywhite.png);
    top: -53px;
    width: 62px;
    height: 65px;
    /* content: ""; */
    transform: scale(0.9);

}

.sec_headingsub {
    font-size: 71px;
    font-weight: 200;
    text-align: left;
    line-height: 76px;
    color: #000;
    padding: 0;
    margin-bottom: 10px;
    font-family: DiastemaBoldItalic;

}

.arrowmanyimgmain {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: 80px;
}

.arrowmanyimgmain img {
    width: 100%;
    height: 100%;
}

.sec_heading span {
    color: #0171b1;
}

/* .HomeBanner_sec .sec_heading {
    max-width: 600px;
} */

.sec_desc {
    font-size: 24px;
    font-weight: 400;
    text-align: left;
    line-height: 36px;
    color: #000;
    padding: 0;
    margin-bottom: 30px;
    /* font-family: "Montserrat", sans-serif; */
    /* font-weight: 200; */
    width: 100%;
    max-width: 684px;
    min-width: 684px;
    font-family: "Lato", sans-serif;
}

.Knowmore_btn {
    width: 100%;
    max-width: 122px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #fff;
    background-color: #1e90ff;
    border: 1px solid #1e90ff;
    cursor: pointer;
    padding: 4px 6px;
    margin: 0;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    transition: 0.5s;
    color: #000;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
}

/* .Knowmore_btn:hover {
    background-color: transparent;
    color: #1e90ff;
} */


.HomeBanner_sec .image_wrap {
    position: relative;
    width: 100%;
    max-width: 353px;
    height: 404px;
    overflow: hidden;
    border-radius: 18px;
}

.HomeBanner_sec .image_wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.HomeBanner_sec .image_wrap .holder {
    position: absolute;
    display: grid;
    place-items: center;
    width: 4em;
    height: 4em;
    background: #000;
}

.HomeBanner_sec .image_wrap .holder::before,
.HomeBanner_sec .image_wrap .holder::after {
    position: absolute;
    content: "";
    width: 1.25em;
    height: 1.25em;
    background: transparent;
}

.HomeBanner_sec .image_wrap .holder.one {
    width: 10em;
    height: 5em;
}

.HomeBanner_sec .image_wrap .one {
    inset: calc(10% - 4em) 0 0 calc(17% - 4em);
    border-bottom-right-radius: 1.25em;
    transition: 1s ease 0.5s;
}

.HomeBanner_sec .image_wrap .one::before {
    inset: calc(5% - -4.75em) 0 0 0.30em;
    border-bottom-right-radius: 1.25em;
    box-shadow: 0.3em 0.3em 0 0.3em #000;
    transition: 1s ease;
    rotate: 180deg;
}

.HomeBanner_sec .image_wrap .one::after {
    inset: 1.2em 0 0 10.0em;
    border-bottom-right-radius: 0.75em;
    box-shadow: 0.3em 0.3em 0 0.3em #000;
    transition: 1s ease;
    rotate: 180deg;
}

.healthcareswipper a {
    text-decoration: none;
}

.HomeBanner_sec .image_wrap .two {
    inset: calc(70% - 2em) 0 0 calc(15% - 4em);
    border-top-right-radius: 1.25em;
    border-bottom-right-radius: 1.25em;
}

.HomeBanner_sec .image_wrap .two::before {
    inset: -1.25em 0 0 calc(100% - 3.25em);
    border-bottom-left-radius: 0.25rem;
    box-shadow: 0.3em 0.3em 0 0.3em #000;
    border-bottom-right-radius: 6.25rem;
    rotate: 90deg;
}

.HomeBanner_sec .image_wrap .two::after {
    inset: 100% 0 0 calc(100% - 3.25em);
    border-top-left-radius: 0;
    border-top-right-radius: 1.25em;
    box-shadow: 0.2em -0.2em 0 0.2em #000;
    rotate: 270deg;
}

.HomeBanner_sec .image_wrap .three {
    inset: calc(50% - 2em) 0 0 calc(100% - 4em);
    border-top-left-radius: 1.25em;
    border-bottom-left-radius: 1.25em;
}

.HomeBanner_sec .image_wrap .three::before {
    inset: -1.25em 0 0 calc(100% - 1.25em);
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.3em 0.3em 0 0.3em #000;
}

.HomeBanner_sec .image_wrap .three::after {
    inset: 100% 0 0 calc(100% - 1.25em);
    border-top-right-radius: 1.25em;
    box-shadow: 0.3em -0.3em 0 0.3em #000;
}

/****ServicesHome_sec*******/
.ServicesHome_sec {
    width: 100%;
    height: auto;
    padding: 50px 0;
}



/********.OurExperienceSlider_sec********/
.OurExperienceSlider_sec {
    width: 100%;
    height: auto;
    padding: 50px 0;
    position: relative;
}

.listuldivmainkkkul {
    list-style-type: none;
}

.listuldivmainkkkliaaa {
    display: flex;
    width: 100%;
    max-width: 8px;
    min-width: 8px;
    height: 8px;
    background-color: #000;
    margin-bottom: 14px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.listuldivmainkkkli {
    display: flex;
    width: 100%;
    max-width: 10px;
    min-width: 10px;
    height: 10px;
    /* background-color: #fff; */
    margin-bottom: 14px;
    border-radius: 50%;
    border: 2px solid #000;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

/* .OurExperienceSlider_sec .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.OurExperienceSlider_sec .OurExperience_Slider {
    width: 100%;
    height: auto;
    padding: 40px 40px 40px 40px;
    display: flex;
    justify-content: space-between;
}

.OurExperienceSlider_sec .imagewrap_box {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 620px;
}

.imagewrap_boxmain {
    width: 100%;
    max-width: 40%;
    min-width: 40%;
}

.OurExperienceSlider_sec .imagewrap_box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.OurExperience_Slider .content_wrap {
    width: 100%;
    max-width: 60%;
    min-width: 60%;
    height: auto;
}

.exploreMore_btn {
    width: 100%;
    max-width: 150px;
    height: 45px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 5px 15px;
    margin: 0;
    text-decoration: none;
    outline: 0;
    box-shadow: none;
    transition: 0.5s;
}

.exploreMore_btn:hover {
    background-color: #fff;
    color: #383838;
}

.progsecaera {
    width: 100%;
    height: auto;
    margin-top: 40px;
}

.progressName {
    font-size: 17px;
    text-align: left;
    font-weight: 600;
    color: #fff;
    line-height: normal;
    letter-spacing: 1px;
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
}

.progress {
    width: 100%;
    height: 10px;
    background-color: transparent;
    border-radius: 1.25rem;
    border: 1px solid #d8d5d5;
}

.progress-bar {
    background-color: #fff;
}

.arrowdivrignt i {
    font-size: 33px;
    color: #000;
    font-weight: 900;
    font-family: "Fontawesome";
    margin-left: 55px;
}

/* .ServicesHome_sec .slick-slide {
    padding: 0px 20px 0px 20px;
}
.swiper-pagination {
    position: relative !important;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
} */
.swiper-pagination-current {
    font-size: 27px;
    color: #000;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.swiper-pagination-total {
    font-size: 27px;
    color: #000;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.swiper-button-prev {
    position: relative !important;
    top: 20px !important;
    left: 38% !important;
    z-index: 99;
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    font-size: 30px;
}

.swiper-button-prev:after {
    content: 'prev';
    font-size: 35px !important;
    color: #000 !important;
    font-weight: 800;
}

.swiper-button-next {
    position: relative !important;
    top: -1px !important;
    left: 61% !important;
    z-index: 99;
}

.swiper-button-next:after {
    /* content: 'prev'; */
    font-size: 35px !important;
    font-weight: 800;
    color: #000 !important;
}

.swiper-wrapper {
    padding-bottom: 40px;
}

.homecarrersectionmaib {
    width: 100%;
    height: auto;
    padding: 50px 0px;
}

.carrergrowdivflx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carrergrowhmomedivlerft {
    width: 100%;
    max-width: 45%;
    min-width: 45%
}

.carrergrowhmomedivlerftimg {
    width: 100%;
    height: 380px;
    position: relative;
}

/* .letstdotogetherttxtppmain {
    position: absolute;
    width: 100%;
} */

.letstdotogetherttxtpp {
    font-size: 44px;
    color: #1e90ff;
    width: 100%;
    /* position: absolute; */
    font-family: DiastemaRegular;
    font-weight: bolder;
    letter-spacing: 4.5px;
    padding-top: 22px;
    /* transform: skew(-1deg, 355deg); */
    /* top: -20px; */

}

.carrergrowhmomedivlerftimg img {
    width: 100%;
    height: 100%;
    border-radius: 40px;
}

.carrergrowhmomedivright {
    width: 100%;
    max-width: 45%;
    min-width: 45%
}

.cattrretxtppsngl {
    color: #000;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 30px;
}

.grwtxttspppcarrte {
    color: #000;
    font-family: AbolitionTestRegular;
    font-size: 35px;
    font-weight: 600;
    line-height: 35px;
    margin-bottom: 30px;
    letter-spacing: 2px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 0px) !important;
    /* top: var(--swiper-pagination-top, auto); */
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: auto !important;
    /* top: auto; */
    /* left: 50%; */
}

.jointxtthometxttpp {
    font-family: "Lato", sans-serif;
    font-size: 24px;
    color: #000;
    line-height: 35px;
    font-weight: 400;
    margin-bottom: 30px;
}

.joimnusbtnnbns {
    border: none;
    outline: 0;
    font-size: 22px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    background-color: transparent;
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
}

.sec_headingbannertset {
    font-size: 98px;
    font-weight: 200;
    text-align: left;
    line-height: 85px;
    color: #fff;
    padding: 0;
    margin-bottom: 0px;
    font-family: AbolitionTestRegular;
}

.sec_headingbannertset span {
    color: #0024ec;
}

.joimnusbtnnbns span {
    background-color: #0171b1;
    width: 100%;
    max-width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.joimnusbtnnbns span i {
    color: #000;
}

button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.OurExperience_Slider {
    text-decoration: none;
}

.OurExperience_Slider:hover {
    text-decoration: none;
}

.sec_desctextppecp {
    color: #ccccccf5;
    font-family: "Lato", sans-serif;
    font-size: 21px;
    font-weight: 400;
    font-weight: 300;
    line-height: 27px;
    margin-bottom: 40px;
    max-width: 600px;
    min-width: 600px;
    padding: 0;
    text-align: left;
    width: 100%;
}

/* .experenceallbgimg {
    width: 100%;
     height: 500px;
     position: relative;
     max-width: 100%;
     min-width: 100%;
} */
.experenceallbgimgmain {
    position: relative;
    width: 100%;
    height: 760px;
    max-width: 100%;
    min-width: 100%;
}

.experenceallbgimgmain img {
    width: 100%;
    height: 100%;

}

.maincontentwrap {
    padding: 70px 85px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0px;

}

.sec_headingexpertxtpp {
    font-size: 70px;
    font-weight: 200;
    text-align: left;
    line-height: 60px;
    color: #fff;
    padding: 0;
    margin-bottom: 0px;
    font-family: AbolitionTestRegular;
    margin-bottom: 28px;
}

.listuldivmainkkk {
    position: absolute;
    top: 4%;
    left: 5%;
    display: none;
}




/*******************/
/*****Responsive****/

@media(max-width:1499px) {
    .letstdotogetherttxtpp {
        font-size: 49px;

    }

    .sec_headingspcl {
        font-size: 86px;

    }

    .sec_headingsub {
        font-size: 64px;

    }
}

@media (max-width: 1263px) {
    .letstdotogetherttxtpp {
        font-size: 47px;
    }

    .sec_headingspcl {
        font-size: 80px;
    }

    .sec_headingsub {
        font-size: 60px;
    }
}

@media(max-width:1399px) {
    .grwtxttspppcarrte {
        font-size: 33px;
    }

    .jointxtthometxttpp {
        font-size: 22px;
        line-height: 34px;
    }

}

@media(max-width:1199px) {
    .grwtxttspppcarrte {
        font-size: 28px;
    }

    .letstdotogetherttxtpp {
        font-size: 40px;
    }

    .sec_headingspcl::after {
        position: absolute;
        content: url(../Images/arrowmanywhite.png);
        top: -36px;
        width: 62px;
        height: 65px;
        /* content: ""; */
        transform: scale(0.6);
    }

    .sec_headingspcl {
        font-size: 75px;
    }

    .jointxtthometxttpp {
        font-size: 20px;
        line-height: 34px;
    }

    .HomeBanner_sec .image_wrap {
        position: relative;
        width: 100%;
        max-width: 309px;
        height: 359px;
        overflow: hidden;
        border-radius: 18px;
    }

    .sec_heading {
        font-size: 75px;
    }

    .sec_headingsub {
        font-size: 56px;

    }

    .sec_desc {
        font-size: 23px;
        width: 100%;
        max-width: 641px;
        min-width: 641px;
    }
}

@media (max-width: 991px) {
    .HomeBanner_sec {
        padding: 40px 0;
    }

    .sec_headingbannertset {
        font-size: 66px;
        font-weight: 200;
        text-align: left;
        line-height: 68px;
        color: #fff;
        padding: 0;
        margin-bottom: 0px;

    }

    .OurExperience_Slider {
        flex-wrap: wrap;
    }

    .sec_desctextppecp {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 25px;

        padding: 0;
        margin-bottom: 40px;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
    }

    .imagewrap_boxmain {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .maincontentwrap {
        padding: 60px 35px;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 0px;
    }

    .OurExperience_Slider .content_wrap {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
    }

    .carrergrowdivflx {
        flex-wrap: wrap;
    }

    .carrergrowhmomedivlerft {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .carrergrowhmomedivright {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-top: 15px;
    }

    .letstdotogetherttxtpp {
        font-size: 25px;

    }

    .carrergrowhmomedivlerftimg {
        width: 100%;
        height: auto;
    }

    .HomeBanner_sec .image_wrap {
        margin: 0 auto;
    }

    .OurExperienceSlider_sec {
        padding: 40px 0;
    }

    .sec_heading {
        font-size: 76px;
        line-height: 55px;
    }

    .sec_desc {
        font-size: 23px;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .cattrretxtppsngl {
        margin-bottom: 15px;
    }

    .grwtxttspppcarrte {
        margin-bottom: 15px;
    }

    .OurExperienceSlider_sec .imagewrap_box {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 361px;
        padding-top: 30px;
    }

    .OurExperience_Slider .content_wrap {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: auto;
    }

    .swiper-button-next {
        position: relative !important;
        top: -1px !important;
        /* left: 53% !important; */
        z-index: 99;
    }

    .swiper-button-prev {
        position: relative !important;
        top: 20px !important;
        /* left: 44% !important; */
        z-index: 99;
    }

    .arrowdivrignt i {
        font-size: 35px;
        color: #fff;
        font-weight: 900;
        font-family: "Fontawesome";
        margin-left: 25px;
    }

    .sec_headingexpertxtpp {
        font-size: 43px;
        font-weight: 200;
        text-align: left;
        line-height: 41px;
        color: #fff;
        padding: 0;
        margin-bottom: 0px;
        font-family: Salita;
        margin-bottom: 10px;
    }

    .homecarrersectionmaib {
        padding: 40px 0px;
    }

    .ServicesHome_sec {
        padding: 40px 0px;
    }
}

@media(max-width:776px) {
    .sec_desc {
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        line-height: 32px;

        padding: 0;
        margin-bottom: 24px;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
    }

    .sec_headingspcl {
        font-size: 64px;
    }

    .sec_headingsub {
        font-size: 46px;
    }

    .listuldivmainkkk {
        position: absolute;
        top: 0%;
        left: 3%;
    }

    .listuldivmainkkkul {
        list-style-type: none;
        display: flex;
        column-gap: 30px;
    }

    .sec_heading {
        font-size: 40px;
        margin-bottom: 15px;
        line-height: 6px;
    }

    .sec_headingbannertset {
        font-size: 57px;
        font-weight: 200;
        text-align: left;
        line-height: 70px;
        color: #fff;
        padding: 0;
        margin-bottom: 0px;
      
    }

    .SectorWeServer_sec .sec_heading {
        font-size: 65px;
        width: 100%;
        max-width: 100%;
    }

    .maincontentwrap {
        padding: 27px 35px;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 0px;
    }

    .experenceallbgimgmain {
        position: relative;
        width: 100%;
        height: auto;
        max-width: 100%;
        min-width: 100%;
    }

    .sec_headingexpertxtpp {
        font-size: 27px;
        font-weight: 200;
        text-align: left;
        line-height: 22px;

        padding: 0;
        margin-bottom: 0px;
        font-family: Salita;
        margin-bottom: 6px;
    }

    .marfbf-22 {
        margin-bottom: 8px;
    }

    .sec_desctextppecp {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 18px;

        padding: 0;
        margin-bottom: 10px;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        width: 100%;
        max-width: 450px;
        min-width: 450px;
    }

    .progsecaera {
        width: 100%;
        height: auto;
        margin-top: 45px;
    }

    .progressName {
        font-size: 11px;
        text-align: left;
        font-weight: 600;
        color: #fff;
        line-height: normal;
        letter-spacing: 1px;
        margin-bottom: 4px;
        font-family: "Montserrat", sans-serif;
    }

    .exploreMore_btn {
        margin: 10px 0px;
    }

    /* .experenceallbgimg:nth-of-type(3) .maincontentwrap  {
        padding: 27px 35px;
        width: 100%;
        max-width: 55%;
        min-width: 55%;
        position: absolute;
        top: 81px;
        left: 0px;
    }
    .experenceallbgimg:nth-of-type(4) .maincontentwrap {
        padding: 27px 35px;
        width: 100%;
        max-width: 55%;
        min-width: 55%;
        position: absolute;
        top: 81px;
        left: 0px;
    } */
}


@media (max-width: 576px) {
    .sec_heading {
        font-size: 30px;
        line-height: 46px;
    }

    .listuldivmainkkk {
        position: absolute;
        top: -1%;
        left: 3%;
    }
    .SectorWeServer_sec .sec_heading {
        font-size: 22px;
        font-weight: 200;
        text-align: left;
        line-height: 34px;
    }
    .sec_headingspcl {
        font-size: 48px;
    }

    .homecarrersectionmaib {
        padding: 20px 0px;
    }

    .sec_desc {
        font-size: 16px;
        line-height: 26px;
        margin-top: 10px;
    }

    .HomeBanner_sec .content_wrap {
        margin-bottom: 40px;
    }

    .letstdotogetherttxtpp {
        font-size: 25px;

    }

    .OurExperienceSlider_sec {
        padding: 20px 0;
    }

    .sec_heading {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 11px;
    }
    .swiper-button-next:after {
        /* content: 'prev'; */
        font-size: 25px !important;
        font-weight: 800;
        color: #000 !important;
    }
    .swiper-button-prev:after {
        content: 'prev';
        font-size: 25px !important;
        color: #000 !important;
        font-weight: 800;
    }
    .swiper-button-next {
        position: relative !important;
        top: -1px !important;
        left: 57% !important;
        z-index: 99;
    }
    .swiper-wrapper {
        padding-bottom: 0px;
    }

    .sec_headingsub {
        font-size: 37px;
        line-height: 40px;
    }

    .sec_desctextppecp {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        line-height: 18px;
        padding: 0;
        margin-bottom: 5px;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        /* height: 150px; */
        max-width: 430px;
        min-width: 430px;
        margin-top: 10px;
    }

    .maincontentwrap:nth-of-type(2) .sec_desctextppecp {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        line-height: 18px;
        padding: 0;
        margin-bottom: 5px;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        /* height: 150px; */
        max-width: 450px;
        min-width: 450px;
        margin-top: 10px;
    }

    .exploreMore_btn {
        width: 100%;
        max-width: 125px;
        height: 31px;
        font-size: 14px;
        margin: 10px 0px;
    }

    .sec_headingexpertxtpp {
        font-size: 21px;
        font-weight: 200;
        text-align: left;
        line-height: 15px;
        color: #fff;
        padding: 0;
        margin-bottom: 0px;
        font-family: Salita;
        margin-bottom: 6px;
    }
    .HomeBanner_sec {
        padding: 20px 0;
    }
    .ServicesHome_sec {
        padding: 20px 0px;
    }

    

   

    .sec_headingspcl::after {
        position: absolute;
        content: url("../Images/arrowmanywhite.png");
        top: -26px;
        width: 62px;
        height: 65px;
        /* content: ""; */
        transform: scale(0.6);
    }

    .progsecaera {
        margin-top: 29px;
    }

    .maincontentwrap:nth-of-type(2) .progsecaera {
        margin-top: 30px;
    }

    .progressName {
        font-size: 10px;
        text-align: left;
        font-weight: 600;
        color: #fff;
        line-height: normal;
        letter-spacing: 1px;
        margin-bottom: 6px;
        font-family: "Montserrat", sans-serif;
    }

    .progress {
        width: 100%;
        height: 4px;
        background-color: transparent;
        border-radius: 1.25rem;
        border: 1px solid #d8d5d5;
    }

    .maincontentwrap {
        padding: 27px 20px;
        width: 100%;
        max-width: 80%;
        min-width: 80%;
        position: absolute;
        top: 0;
        left: 0px;
    }

    /* .swiper-button-prev {
        position: relative !important;
        top: 20px !important;
        left: 42% !important;
        z-index: 99;
    } */
    /* 
    .swiper-pagination-current {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
    } */

    /* .swiper-pagination-total {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;


    } */

    /* .swiper-button-next {
        position: relative !important;
        top: 2px !important;
        left: 54% !important;
        z-index: 99;
    }
    .swiper-button-prev {
        position: relative !important;
        top: 25px !important;
        left: 42% !important;
        z-index: 99;
    } */
    /* .swiper-button-next:after {
        content: 'prev';
        font-size: 22px !important;
        color: #fff !important;
    }
    .swiper-button-prev:after {
        content: 'prev';
        font-size: 22px !important;
        color: #fff !important;
    } */
}

@media (max-width: 480px) {
    .HomeBanner_sec {
        padding: 30px 0;
    }

    .experenceallbgimgmain {
        position: relative;
        width: 100%;
        height: 342px;
        max-width: 100%;
        min-width: 100%;
    }

    .sec_heading {
        font-size: 22px;
        line-height: 30px;
        text-align: left;
        margin-bottom: 21px;
    }

    .letstdotogetherttxtpp {
        font-size: 25px;

    }
    .carrergrowhmomedivright {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        padding-top: 22px;
    }

    .sec_desctextppecp {
        font-size: 10px;
        font-weight: 400;
        text-align: left;
        line-height: 14px;
        padding: 0;
        margin-bottom: 5px;
        font-family: "Montserrat", sans-serif;
        font-weight: 300;
        max-width: 250px;
        min-width: 250px;
        /* height: 185px; */
    }

    .exploreMore_btn {
        width: 100%;
        max-width: 102px;
        height: 26px;
        font-size: 11px;
        margin: 10px 0px;
    }

    .sec_headingsub {
        font-size: 35px;
        line-height: 11px;
    }

    .sec_headingspcl::after {
        position: absolute;
        content: url(../Images/arrowmanywhite.png);
        position: absolute;
        top: -4px;
        width: 31px;
        height: 65px;
        transform: scale(0.2);

    }

    .grwtxttspppcarrte {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .jointxtthometxttpp {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .cattrretxtppsngl {
        margin-bottom: 10px;
    }

    .joimnusbtnnbns {
        font-size: 19px;
        column-gap: 10px;
    }

    .sec_desc {
        font-size: 12px;
        line-height: 20px;
        text-align: left;

        margin-bottom: 15px;
        margin-top: 27px;
    }

    .sec_headingexpertxtpp {
        font-size: 15px;
        font-weight: 200;
        text-align: left;
        line-height: 15px;
        color: #fff;
        padding: 0;
        margin-bottom: 0px;
        font-family: Salita;
        margin-bottom: 6px;
    }

    .Knowmore_btn {
        width: 100%;
        max-width: 120px;
        height: 35px;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    .OurExperienceSlider_sec {
        padding: 20px 0;
    }

    .sec_heading {
        font-size: 30px;
    }

    /* .swiper-button-prev {
        position: relative !important;
        top: 24px !important;
        left: 37% !important;
        z-index: 99;
    }
    .swiper-button-next {
        position: relative !important;
        top: 2px !important;
        left: 57% !important;
        z-index: 99;
    } */
    .progressName {
        font-size: 7px;
        text-align: left;
        font-weight: 600;
        color: #fff;
        line-height: normal;
        letter-spacing: 1px;
        line-height: 10px;
    }

   

    .OurExperienceSlider_sec .OurExperience_Slider {
        width: 100%;
        height: auto;
        padding: 40px 20px 40px 20px;
        display: flex;
        justify-content: space-between;
    }
}

@media(max-width:420px) {
    .swiper-button-prev {
        position: relative !important;
        top: 20px !important;
        left: 34% !important;
        z-index: 99;
    }
    .swiper-pagination-current {
        font-size: 22px;
        color: #000;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
    }
    .swiper-pagination-total {
        font-size: 22px;
        color: #000;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
    }
    .sec_headingspcl::after {
        position: absolute;
        top: -17px;
        width: 31px;
        height: 65px;
        transform: scale(0.2);
    }

    .arrowdivrignt i {
        font-size: 22px;
        color: #fff;
        font-weight: 900;
        font-family: "Fontawesome";
        margin-left: 13px;
    }

    .progsecaera {
        margin-top: 4px;
    }

    .letstdotogetherttxtpp {
        font-size: 14px;
        padding-top: 5px;

    }

    .certtsiisfoimdivflx {
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
    }

    .sec_headingspcl {
        font-size: 35px;
        line-height: 40px;
    }

    .sec_headingsub {
        font-size: 27px;
        line-height: 16px;

    }
}